<template>
    <div class="inventoryMonitor">
        <div class="close">
            <span class="iconfont iconguanbi"></span>
        </div>
        <div class="tit">
            <div class="col1">
                <div class="max">
                    -最大库存线
                </div>
                <div class="actual">
                    -实际库存线
                </div>
                <div class="warn">
                    -库存预警线
                </div>
            </div>
            <div class="col2">
                料仓监控
            </div>
            <div class="col1 col3">
                <el-select
                    v-model="enterpriseId"
                    placeholder="请选择"
                    v-if="enterpriseData.length>0"
                >
                    <el-option
                        v-for="item in enterpriseData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select
                    v-model="defaultStationId"
                    placeholder="请选择"
                    v-if="stationData.length>0"
                >
                    <el-option
                        v-for="item in stationData"
                        :key="item.station_id"
                        :label="item.name"
                        :value="item.station_id"
                    >
                    </el-option>
                </el-select>
                <el-select
                    v-model="selectedBin"
                    placeholder="请选择"
                    v-if="siloGroupingData.length>0"
                >
                    <el-option
                        v-for="item in siloGroupingData"
                        :key="item.grouping_number"
                        :label="item.grouping_name"
                        :value="item.grouping_number"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="main-con">
            <!-- 骨料 -->
            <div class="main-categories" v-if="monitorList.length > 0">
                <ul>
                    <li
                        v-for="(item, index) in monitorList"
                        :class="{'active':item.relatedProductionLine}"
                        :key="`stock${index}`"
                        @click="selectBin(item)"
                    >
                        <h4>{{ item.stock_no }}</h4>
                        <h4>{{ item.stock_name }}</h4>
                        <div class="item" :class="{'item-blue':item.type_dic_code == '204','item-green':item.type_dic_code == '203'}">
                            <div class="amount-height" :style="{height:item.volume_theory_height}"></div>
                            <div class="max-text">
                                {{ item.volume_max }}吨-
                            </div>
                            <div class="actual-text" :style="{top:item.volume_theory_top,right:item.volume_theory_right}">
                                {{ item.volume_theory }}吨-
                            </div>
                            <div class="warn-text" :style="{top:item.volume_warn_top,right:item.volume_warn_right}">
                                {{ item.volume_warn }}吨-
                            </div>
                            <div v-if="item.source_name" class="name-tag">
                                {{ item.source_name }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 粉料 -->
            <div class="station-line-con" v-if="stock_physics.length > 0">
                <div class="station-line">
                    <div
                        class="line-item"
                        v-for="item in lineList"
                        :key="item.id"
                        :class="{'relatedProductionLine': item.relatedProductionLine}"
                        @click="selectProductionLine(item)"
                    >
                        <div class="tit-tag">
                            <span>{{ item.name }}</span>
                        </div>
                        <template v-for="(k, i) in stock_physics">
                            <div :key="i" v-if="item.no == k.title">
                                <div class="material-con">
                                    <ul>
                                        <template v-for="(j,z) in k.sur_name">
                                            <li :class="j.class" :key="z" v-if="j.type_dic_code != '208'">
                                                <h4>{{ j.stock_name }}</h4>
                                                <div class="material-item">
                                                    <div class="bottom-bg"></div>
                                                    <div class="material-main" :style="{height: j.volume_theory_height}">
                                                        <div class="material-top"></div>
                                                    </div>
                                                    <div class="name-tag" v-if="j.source_name">
                                                        {{ j.source_name }}
                                                    </div>
                                                    <div class="max-text">
                                                        {{ j.volume_max }}吨-
                                                    </div>
                                                    <div class="warn-text" :style="{bottom: j.volume_warn_height}">
                                                        {{ j.volume_warn }}吨-
                                                    </div>
                                                    <div class="actual-text" :style="{bottom: j.volume_theory_text_height}">
                                                        {{ j.volume_theory }}吨-
                                                    </div>
                                                    <div class="material-item-top"></div>
                                                </div>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <div class="admixture-con">
                                    <template v-for="(j,z) in k.sur_name">
                                        <div class="admixture-item" :key="z" v-if="j.type_dic_code == '208'">
                                            <div class="admixture-cylinder">
                                                <div class="admixture-main" :style="{height: j.volume_theory_height}">
                                                    <div class="admixture-top"></div>
                                                </div>
                                                <div class="admixture-bottom"></div>
                                                <div class="admixture-cylinder-top"></div>
                                                <div class="max-text">
                                                    {{ j.volume_max }}吨-
                                                </div>
                                                <div class="warn-text" :style="{bottom: j.volume_warn_height}">
                                                    {{ j.volume_warn }}吨-
                                                </div>
                                                <div class="actual-text" :style="{bottom: j.volume_theory_height}">
                                                    {{ j.volume_theory }}吨-
                                                </div>
                                            </div>
                                            <div class="tit">
                                                {{ j.stock_name }}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <!-- 数据 -->
            <div class="data-table">
                <table
                    class="custom-table"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tbody>
                        <tr>
                            <td class="title">
                                <h4>原材料</h4>
                                <p>货源地</p>
                                <p>最大库存</p>
                                <p>预警库存</p>
                                <p>当前库存</p>
                            </td>
                            <template v-for="(item, index) in tableData">
                                <td :key="`td${index}`">
                                    <h4 class="first-th">
                                        {{ item.stock_name }}
                                    </h4>
                                    <p>{{ item.source_name }}</p>
                                    <p class="QuartzLTStd max-stock">
                                        {{ item.volume_max }}
                                    </p>
                                    <p class="QuartzLTStd warn-stock">
                                        {{ item.volume_warn }}
                                    </p>
                                    <p class="QuartzLTStd current-stock">
                                        {{ item.volume_theory }}
                                    </p>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            // 选中企业
            enterpriseId: '',
            // 选中企业场站id
            enterpriseTenantId: '',
            // 企业数据
            enterpriseData: [
                {
                    name: '济宁商混',
                    id: 'OT000050',
                    tenant_id: 'T200103000004',
                }, {
                    name: '东营商混',
                    id: 'OT000072',
                    tenant_id: 'T200103000003',
                }, {
                    name: '青州中联',
                    id: 'OT000374',
                    tenant_id: 'T200103000002',
                },
            ],
            // 生产线列表
            lineList: [],
            // 料仓列表
            monitorList: [],
            // 列表数据
            tableData: [],
            // 生产线物理料仓数据
            stock_physics: [],
            // 料仓关系数据
            linestockrelationData: [],
            // 场站数据
            stationData: [],
            // 默认场站id
            defaultStationId: '',
            // 料仓分组数据
            siloGroupingData: [{
                grouping_name: '全部',
                grouping_number: '',
            }],
            // 选中的料仓分组
            selectedBin: '',
            // 生产线列表数据备份
            lineListBackups: [],
            // 料仓列表备份
            monitorListBackups: [],
            // 生产线物理料仓数据备份
            stock_physicsBackups: [],
        };
    },
    created() {
        this.enterpriseId = this.$takeTokenParameters('CompanyId');
        this.enterpriseTenantId = this.$takeTokenParameters('TenantId');
        const tenantName = this.$takeTokenParameters('TenantName');

        if (this.enterpriseId === 'OT000072' || this.enterpriseId === 'OT000374') {
            this.enterpriseData = [{
                name: '东营商混',
                id: 'OT000072',
                tenant_id: 'T200103000003',
            }, {
                name: '青州中联',
                id: 'OT000374',
                tenant_id: 'T200103000002',
            }];
        } else {
            this.enterpriseData = [{
                name: tenantName,
                id: this.enterpriseId,
                tenant_id: this.enterpriseTenantId,
            }];
        }

        this.defaultStationId = this.$takeTokenParameters('StationId');
        if (this.defaultStationId === '') {
            this.getStationData();
        } else {
            this.getLineList();
            this.getStockMonitor();
            this.getLinestockrelation();
        }
    },
    watch: {
        enterpriseId(newVal) {
            this.enterpriseData.forEach(item => {
                if (item.id === newVal) {
                    this.enterpriseTenantId = item.tenant_id;
                    this.getStationData();
                }
            });
        },
        defaultStationId() {
            this.getLineList();
            this.getStockMonitor();
            this.getLinestockrelation();
            // 获取料仓分组数据
            this.getSiloGroupingData();
        },
        selectedBin(newVal) {
            if (newVal === '') {
                // 生产线列表
                this.lineList = this.lineListBackups;
                // 料仓列表
                this.monitorList = this.monitorListBackups;
                // 生产线物理料仓数据
                this.stock_physics = this.stock_physicsBackups;
            } else {
                // 获取当前料仓分组关联料仓
                this.getFilterData();
            }
        },
    },
    computed: {},
    methods: {
        // 获取站点数据
        getStationData() {
            this.$axios
                .get(`/interfaceApi/baseinfo/stationmanger/get_station_list?companyId=${this.enterpriseId}&tenant_id=${this.enterpriseTenantId}` )
                .then(res => {
                    if (res) {
                        this.stationData = res;
                        this.defaultStationId = res[0].station_id;
                        this.getLineList();
                        this.getStockMonitor();
                        this.getLinestockrelation();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉取场站下生产机组列表
        getLineList() {
            this.$axios
                .get(`/interfaceApi/baseinfo/stationmanger/get_line_list?stationId=${this.defaultStationId}&tenant_id=${this.enterpriseTenantId}`)
                .then(res => {
                    if (res) {
                        this.lineList = res;
                        this.lineListBackups = JSON.parse(JSON.stringify( this.lineList ));
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 料仓监控获取料仓信息
        getStockMonitor() {
            this.$axios
                .get(`/interfaceApi/procurement/stock/monitor/${this.defaultStationId}?tenant_id=${this.enterpriseTenantId}`)
                .then(res => {
                    if (res) {
                        const lineStockData = [];
                        this.monitorList = [];
                        this.stock_physics = [];
                        this.tableData = res;
                        res.map(item => {
                            if (item.type_dic_code === '203' || item.type_dic_code === '204') {
                                if (item.volume_warn > 0) {
                                    item.volume_warn_top = ((item.volume_max - item.volume_warn) / item.volume_max * 100).toFixed(2) + '%';
                                    item.volume_warn_right = ((item.volume_max - item.volume_warn) / item.volume_max * 30).toFixed(2) + 'px';
                                } else {
                                    item.volume_warn_top = ((item.volume_max - item.volume_warn - 10) / item.volume_max * 100).toFixed(2) + '%';
                                    item.volume_warn_right = '30px';
                                }
                                if (item.volume_theory > 0) {
                                    item.volume_theory_top = ((item.volume_max - item.volume_theory - 10) / item.volume_max * 100).toFixed(2) + '%';
                                    item.volume_theory_right = ((item.volume_max - item.volume_theory) / item.volume_max * 30).toFixed(2) + 'px';
                                    // eslint-disable-next-line max-len
                                    item.volume_theory_height = 100 - ((item.volume_max - item.volume_theory) / item.volume_max * 100).toFixed(2) + '%';
                                } else {
                                    item.volume_theory_top = ((item.volume_max - 12) / item.volume_max * 100).toFixed(2) + '%';
                                    item.volume_theory_right = '30px';
                                    item.volume_theory_height = 0;
                                }
                                this.monitorList.push(item);
                                // 料仓列表备份
                                this.monitorListBackups = JSON.parse(JSON.stringify( this.monitorList ));
                            } else {
                                lineStockData.push(item);
                            }
                        });
                        // 生产线数据处理
                        this.productionLineDataProcessing(lineStockData);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 生产线数据处理
        productionLineDataProcessing(data) {
            if (data.length > 0) {
                let flag = 0;
                let wdy = {
                    title: '',
                    sur_name: '',
                };
                for (let i = 0; i < data.length; i++) {
                    if (data[i].type_dic_code !== '208') {
                        if (data[i].type_dic_code === '202') {
                            data[i].class = 'material-green';
                        } else if (data[i].type_dic_code === '205-FMH') {
                            data[i].class = 'material-orange';
                        } else if (data[i].type_dic_code === '205-KZF') {
                            data[i].class = 'material-purple';
                        } else if (data[i].type_dic_code === '205-SHF') {
                            data[i].class = 'material-blue';
                        }
                        data[i].volume_warn_height = Math.floor(86 / data[i].volume_max * data[i].volume_warn) + 36 + 'px';
                        let newHeight = Math.floor(86 / data[i].volume_max * data[i].volume_theory);
                        if (newHeight > 86) {
                            newHeight = 86;
                        }
                        data[i].volume_theory_height = newHeight + 'px';

                        let newBottom = Math.floor(86 / data[i].volume_max * data[i].volume_theory) + 36;
                        if (newBottom > 120) {
                            newBottom = 120;
                        }
                        data[i].volume_theory_text_height = newBottom + 'px';
                    } else {
                        data[i].volume_warn_height = Math.floor(48 / data[i].volume_max * data[i].volume_warn) + 'px';
                        let newHeight = Math.floor(48 / data[i].volume_max * data[i].volume_theory);
                        if (newHeight > 48) {
                            newHeight = 48;
                        }
                        data[i].volume_theory_height = newHeight + 'px';
                    }
                    let az = '';
                    for (let j = 0; j < this.stock_physics.length; j++) {
                        if (this.stock_physics[j].title === data[i].team_id) {
                            flag = 1;
                            az = j;
                            break;
                        }
                    }
                    if (flag === 1) {
                        const ab = this.stock_physics[az];
                        ab.sur_name.push(data[i]);
                        flag = 0;

                    } else if (flag === 0) {
                        wdy = {};
                        wdy.title = data[i].team_id;
                        wdy.sur_name = new Array();
                        wdy.sur_name.push(data[i]);
                        this.stock_physics.push(wdy);
                    }
                }

                const singleStationData = [];
                const multiStationData = [];
                this.stock_physics.forEach(item => {
                    const newTitle = item.title.split(',');
                    if (newTitle.length === 1) {
                        singleStationData.push(item);
                    } else {
                        multiStationData.push(item);
                    }
                });
                multiStationData.forEach(item => {
                    singleStationData.forEach(z => {
                        if (item.title.includes(z.title)) {
                            z.sur_name.push(...item.sur_name);
                        }
                    });
                });
                this.stock_physics = singleStationData;

                // 生产线物理料仓数据备份
                this.stock_physicsBackups = JSON.parse(JSON.stringify( this.stock_physics ));
            }
        },

        // 选择料仓
        selectBin(data) {
            this.monitorList.forEach(item => {
                item.relatedProductionLine = false;
            });
            if (data.relatedProductionLine) {
                data.relatedProductionLine = false;
            } else {
                data.relatedProductionLine = true;
            }

            this.linestockrelationData.forEach(item => {
                if (data.type_dic_code === item.stock_category_dictcode) {
                    this.lineList.forEach(k => {
                        if (item.line_code === k.no) {
                            k.relatedProductionLine = data.relatedProductionLine;
                        }
                    });
                }
            });
            this.$forceUpdate();
        },
        // 选择生产线
        selectProductionLine(data) {
            this.lineList.forEach(item => {
                item.relatedProductionLine = false;
            });
            if (data.relatedProductionLine) {
                data.relatedProductionLine = false;
            } else {
                data.relatedProductionLine = true;
            }
            this.linestockrelationData.forEach(item => {
                if (data.no === item.line_code) {
                    this.monitorList.forEach(k => {
                        if (item.stock_category_dictcode === k.type_dic_code) {
                            k.relatedProductionLine = data.relatedProductionLine;
                        }
                    });
                }
            });
            this.$forceUpdate();
        },
        // 获取工控料仓关系
        getLinestockrelation() {
            this.$axios
                .get(`/interfaceApi/production/productsilo/linestockrelation?dictcodes=203,204$&tenant_id=${this.enterpriseTenantId}`)
                .then(res => {
                    if (res) {
                        this.linestockrelationData = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取料仓分组数据
        getSiloGroupingData() {
            this.siloGroupingData = [{
                grouping_name: '全部',
                grouping_number: '',
            }];
            this.$axios
                .get(`/interfaceApi/procurement/stock/get_stock_grouping/${this.defaultStationId}`)
                .then(res => {
                    if (res && res.length > 0) {
                        this.siloGroupingData.push(...res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取当前料仓分组关联料仓
        getFilterData() {
            this.$axios
                .get(`/interfaceApi/procurement/stock/get_stock_grouping_extend/${this.selectedBin}`)
                .then(res => {
                    const newMonitorList = [];
                    if (res.stock_codes.length > 0) {
                        res.stock_codes.forEach(item => {
                            this.monitorListBackups.forEach(k => {
                                if (item === k.stock_code) {
                                    newMonitorList.push(k);
                                }
                            });
                        });
                    }
                    // 料仓列表
                    this.monitorList = newMonitorList;

                    const newStockPhysics = [];
                    if (res.team_ids.length > 0) {
                        res.team_ids.forEach(item => {
                            this.stock_physicsBackups.forEach(k => {
                                if (item === k.title) {
                                    const res = JSON.parse(JSON.stringify(k));
                                    newStockPhysics.push(res);
                                }
                            });
                        });
                    }

                    if (newStockPhysics.length > 0) {
                        newStockPhysics.forEach(item => {
                            const stock_physics_xn = [];
                            item.sur_name.forEach(k => {
                                if (res.stock_codes.includes(k.stock_code)) {
                                    stock_physics_xn.push(k);
                                }
                            });
                            item.sur_name = stock_physics_xn;
                        });
                    }

                    // 生产线物理料仓数据
                    this.stock_physics = newStockPhysics;

                    const newLineList = [];
                    this.stock_physics.forEach(item => {
                        this.lineListBackups.forEach(k => {
                            if (k.no === item.title) {
                                newLineList.push(k);
                            }
                        });
                    });
                    // 生产线列表
                    this.lineList = newLineList;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    mounted() {},
};
</script>
<style lang="stylus">
.inventoryMonitor
    background #E0E5EB
    height 100%
    padding .2rem
    .close
        position absolute
        width .6rem
        height .6rem
        line-height .6rem
        right 0
        cursor pointer
    .tit
        height .6rem
        background url(./../images/title_bg.png) no-repeat
        background-size 100% 100%
        display flex
        .col2
            flex 2
            height .6rem
            line-height .6rem
            text-align center
            font-size .18rem
        .col1
            flex 1
            display flex
            line-height .6rem
            text-align center
            font-size .14rem
            .max
                flex 1
                color #3C99FB
            .actual
                flex 1
                color #169AAD
            .warn
                flex 1
                color #EE4F4F
        .col3
            box-sizing border-box
            display flex
            .el-select
                width 1.2rem
                .el-input__inner
                    background: none
                    border none
                    color #FF9154
                    text-align center
                .el-input__icon
                    height:  auto
    .main-con
        height calc(100% - .8rem)
        margin-top .2rem
        overflow-x hidden
        overflow-y auto
        .main-categories
            padding .2rem
            background #fff
            margin-bottom .2rem
            overflow-x auto
            ul
                width fit-content
                overflow hidden
                display flex
                li
                    width 170px
                    margin-right 20px
                    padding 10px 10px 20px 10px
                    border:2px solid rgba(115,152,180,0);
                    &.active
                        border:2px solid rgba(115,152,180,1);
                        border-radius:8px;
                    h4
                        text-align center
                        margin-bottom .1rem
                        height .21rem
                    .item
                        width 150px
                        height 170px
                        background url(./../images/inventory_bg.png) no-repeat
                        background-size 100% 100%
                        position relative
                        font-size 14px
                        .amount-height
                            position absolute
                            bottom 0
                            left 0
                            width 100%
                        .max-text
                            position absolute
                            top -10px
                            color #3C99FB
                            right 0
                            text-shadow 1px 1px 1px #fff
                        .warn-text
                            position absolute
                            color #EE4F4F
                            right 5px
                            text-shadow 1px 1px 1px #fff
                        .actual-text
                            position absolute
                            color #169AAD
                            z-index 9
                            text-shadow 1px 1px 1px #fff
                        &.item-green
                            .amount-height
                                background url(./../images/inventory_bg_green.png) no-repeat
                                background-position bottom center
                                background-size 100%
                            .name-tag
                                background:linear-gradient(90deg,rgba(15,155,175,1),rgba(29,199,224,1));
                        &.item-blue
                            .amount-height
                                background url(./../images/inventory_bg_blue.png) no-repeat
                                background-position bottom center
                                background-size 100%
                            .name-tag
                                background:linear-gradient(90deg,rgba(46,69,141,1),rgba(123,178,234,1));
                        .name-tag
                            position absolute
                            width 60px
                            height 30px
                            bottom 10px
                            left 50%
                            margin-left -30px
                            text-align center
                            line-height 30px
                            color #fff
        .station-line-con
            overflow-x auto
            margin-bottom: .1rem;
            .station-line
                width fit-content
                display flex
                overflow hidden
                .line-item
                    width auto
                    padding 0.1rem
                    margin 0.1rem
                    background #fff
                    border:3px solid rgba(115,152,180,0);
                    &.relatedProductionLine
                        border:3px solid rgba(115,152,180,1);
                        box-shadow:0px 2px 10px 0px rgba(0, 0, 0, 0.35);
                    .tit-tag
                        text-align center
                        span
                            display inline-block
                            width 80px
                            height 35px
                            text-align center
                            line-height 35px
                            background #1487DC
                            color #fff
                    .material-con
                        ul
                            display flex
                            li
                                flex 1
                                margin 0 10px
                                text-align center
                                display table-cell
                                vertical-align middle
                                .material-item
                                    background url(./../images/material-item.png) no-repeat
                                    width 50px
                                    height 136px
                                    background-size 100% 100%
                                    position relative
                                    margin 0 auto
                                    .bottom-bg
                                        position absolute
                                        bottom 0
                                        width 50px
                                        height 36px
                                        left 0
                                    .material-main
                                        height 50px
                                        position absolute
                                        bottom 35px
                                        left 0
                                        width 100%
                                        border-left 2px solid rgba(215,215,215,1)
                                        border-right 2px solid rgba(215,215,215,1)
                                        border-radius 0 2px 0 2px
                                        .material-top
                                            position absolute
                                            width 46px
                                            left 0
                                            height 26.8px
                                            top -13.4px
                                    .name-tag
                                        position absolute
                                        bottom 32px
                                        width 28px
                                        left 50%
                                        margin-left -14px
                                        font-size 12px
                                        color #fff
                                    .max-text
                                        position absolute
                                        font-size 12px
                                        width 100%
                                        text-align right
                                        color #3C99FB
                                        text-shadow 1px 1px 1px #fff
                                        z-index 6
                                    .warn-text
                                        position absolute
                                        font-size 12px
                                        bottom 20px
                                        width 100%
                                        text-align right
                                        color #EE4F4F
                                        text-shadow 1px 1px 1px #fff
                                        z-index 6
                                    .actual-text
                                        position absolute
                                        bottom 40px
                                        font-size 12px
                                        width 100%
                                        text-align right
                                        color #169AAD
                                        text-shadow 1px 1px 1px #fff
                                    .material-item-top
                                        position absolute
                                        top 0
                                        left 0
                                        width 100%
                                        height 26.8px
                                        background url(./../images/cylinder_top_56.png) no-repeat
                                        background-size 100% 100%
                                h4
                                    font-size 12px
                                    text-align center
                                    margin 10px 0 5px 0
                                    height 0.32rem
                                    display inline-block
                                    font-size 0.1em
                                    vertical-align middle
                                &.material-green
                                    .bottom-bg
                                        background url(./../images/triangle1.png) no-repeat
                                        background-size 100% 100%
                                    .material-main
                                        background:linear-gradient(0deg,rgba(3,168,123,1),rgba(147,238,168,1));
                                        .material-top
                                            background url(./../images/circle1.png) no-repeat
                                            background-size 100% 100%
                                &.material-orange
                                    .bottom-bg
                                        background url(./../images/triangle2.png) no-repeat
                                        background-size 100% 100%
                                    .material-main
                                        background:linear-gradient(0deg,rgba(228,70,66,1),rgba(251,146,100,1));
                                        .material-top
                                            background url(./../images/circle2.png) no-repeat
                                            background-size 100% 100%
                                &.material-purple
                                    .bottom-bg
                                        background url(./../images/triangle3.png) no-repeat
                                        background-size 100% 100%
                                    .material-main
                                        background:linear-gradient(0deg,rgba(66,49,163,1),rgba(171,118,231,1));
                                        .material-top
                                            background url(./../images/circle3.png) no-repeat
                                            background-size 100% 100%
                                &.material-blue
                                    .bottom-bg
                                        background url(./../images/triangle4.png) no-repeat
                                        background-size 100% 100%
                                    .material-main
                                        background:linear-gradient(0deg,rgba(32,101,213,1),rgba(103,199,254,1));
                                        .material-top
                                            background url(./../images/circle4.png) no-repeat
                                            background-size 100% 100%
                    &:last-child
                        margin-right 0
                    .admixture-con
                        display flex
                        margin-top 10px
                        justify-content space-between
                        .admixture-item
                            flex 1
                            margin 0 10px
                            text-align center
                            .admixture-cylinder
                                display inline-block
                                width 50px
                                height 76px
                                background url(./../images/bg5.png) no-repeat
                                background-size 100% 100%
                                position relative
                                font-size 12px
                                .admixture-main
                                    height 0px
                                    background:linear-gradient(0deg,rgba(255,164,1,1),rgba(251,232,113,1));\
                                    position absolute
                                    width 100%
                                    left 0
                                    bottom 13.4px
                                    z-index 2
                                    .admixture-top
                                        position absolute
                                        left 0
                                        top -13.4px
                                        width 100%
                                        height 26.8px
                                        background url(./../images/circle5.png) no-repeat
                                        background-size 100% 100%
                                .admixture-bottom
                                    position absolute
                                    bottom 0
                                    left 0
                                    width 100%
                                    height 13.4px
                                    background url(./../images/circle5_bottom.png) no-repeat
                                    background-size 100% 100%
                                .admixture-cylinder-top
                                    position absolute
                                    top 0
                                    left 0
                                    width 100%
                                    height 27.6px
                                    background url(./../images/cylinder_top_58.png) no-repeat
                                    background-size 100% 100%
                                .max-text
                                    position absolute
                                    top 2px
                                    color #3C99FB
                                    right 0
                                    text-shadow 1px 1px 1px #fff
                                .warn-text
                                    position absolute
                                    bottom 0px
                                    color #EE4F4F
                                    right 0
                                    text-shadow 1px 1px 1px #fff
                                    z-index 3
                                .actual-text
                                    position absolute
                                    color #169AAD
                                    bottom 0px
                                    right 0
                                    text-shadow 1px 1px 1px #fff
                                    z-index 3
                            .tit
                                font-size 12px
                                text-align center
                                margin-top 5px
                                display block
        .data-table
            width 100%
            height auto
            overflow-x auto
            overflow-y hidden
            .custom-table
                border-right 1px solid #D7D7D7
                border-bottom 1px solid #D7D7D7
                height 190px
                background #fff
                width 100%
                tbody
                    width 100%
                    tr
                        width 100%
                        td
                            border-left 1px solid #D7D7D7
                            border-top 1px solid #D7D7D7
                            word-break keep-all
                            white-space nowrap
                            overflow hidden
                            text-overflow ellipsis
                            height 37px
                            line-height 37px
                            font-size 14px
                            width 5%
                            text-align center
                            &.title
                                h4,p
                                    color #022782
                            h4,p
                                height 0.4rem
                                border-bottom 1px solid #D7D7D7
                                padding 0 .12rem
                                font-weight bold
                                font-size 0.16rem
                            h4
                                color #333333
                            .QuartzLTStd
                                font-family QuartzLTStd
                            .max-stock
                                &:not(:first-child)
                                    color #3C99FB
                            .warn-stock
                                &:not(:first-child)
                                    color #EE4F4F
                            .current-stock
                                border-bottom none
                                &:not(:first-child)
                                    color #169AAD
</style>